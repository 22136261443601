import './App.css';
import { ChakraProvider, Text, Link, Box } from "@chakra-ui/react";
import Layout from './components/layout';
import ConnectButton from './components/Utils/ConnectButton';
import ArticleLink from './components/Utils/ArticleLink';

export default function App() {

  return (
    <ChakraProvider>
      <Layout>
        <Box display="flex">
          <Text color="white" fontSize='28px' margin='1' marginBottom='30px'>Welcome to </Text>
          <Link href='https://web3.xyz/'  _hover={{ textDecoration: "none"}} isExternal color="white" fontSize='28px' margin='1' marginBottom='30px'>Web3</Link>
          <Text color="white" fontSize='28px' margin='1' marginBottom='30px'>universe</Text>
        </Box>
        <ConnectButton />
        <ArticleLink />
      </Layout>
    </ChakraProvider>
  )
}