import { Box, Link } from "@chakra-ui/react";

export default function ArticleLink() {
    return (
      <Box alignItems="center" justifyItems="center" marginTop="30px">

          <Box textAlign="center" marginTop="20px" marginBottom="40px" padding="14px" backgroundColor="white" borderRadius="xl">
            <Link href='https://learn.rainbow.me/understanding-blockchains'  _hover={{ textDecoration: "none"}} isExternal color={'black'}>Understanding blockchains</Link>
          </Box>

          <Box textAlign="center" marginop="20px" marginBottom="40px" padding="14px" backgroundColor="white" borderRadius="xl">
            <Link href='https://learn.rainbow.me/understanding-web3' _hover={{ textDecoration: "none"}} isExternal color={'black'}>Understanding Web3</Link>
          </Box>

          <Box textAlign="center" marginTop="20px" marginBottom="40px" padding="14px" backgroundColor="white" borderRadius="xl">
            <Link href='https://learn.rainbow.me/what-is-a-cryptoweb3-wallet-actually' _hover={{ textDecoration: "none"}} isExternal color={'black'}>What is a web3 wallet ?</Link>
          </Box>

          <Box textAlign="center" marginTop="20px" marginottom="40px" padding="14px" backgroundColor="#95d5b2" borderRadius="xl">
            <Link href='https://ethereum.org/en/energy-consumption/' fontWeight='600' _hover={{ textDecoration: "none"}} isExternal color={'black'}>Blockchains and ecology</Link>
          </Box>

      </Box>
    )
}